import { useEffect } from "react";
import { FAQSection } from "./sections/faq/faq.section";
import { FeaturesSection } from "./sections/features/features.section";
import { FooterSection } from "./sections/footer/footer.section";
import { HeroSection } from "./sections/hero/hero.section";

export const LandingPage = () => {
  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <FAQSection />
      <FooterSection />
    </>
  );
};
