import { ConfigProvider } from "antd";
import styled from "styled-components";
import { colors } from "./theme/colors";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { useEffect } from "react";
import { ResponseWithMessage } from "./types/ResponseWithMessage";
import { User } from "./models/user.model";
import { axios } from "./api/axios";

function App() {
  useEffect(() => {
    axios
      .get<ResponseWithMessage<{ user: User }>>("/auth/user/me")
      .then((response) => {
        if (response.data.user && process.env.NODE_ENV !== "development") {
          // window.location.replace("https://play.pocketfilms.in");
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary.hex,
          colorText: "#fff",
          colorBgBase: "#07121d",
          colorBgContainer: "#07121d",
          colorBorder: "rgba(0,0,0,0)",
        },
        components: {
          Button: {
            boxShadow: "0px 0px transparent",
          },
        },
      }}
    >
      <Container>
        <RouterProvider router={router}></RouterProvider>
      </Container>
    </ConfigProvider>
  );
}

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #040c15;
`;

export default App;
